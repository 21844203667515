export const duizi=[
    {
        ResultID:21943,
        label:1
    },
    {
        ResultID:21881,
        label:10
    },
    {
        ResultID:21882,
        label:'豹子全包'
    }
]
export const shuangfei=[
  
    {
        ResultID: 21884,
        label:'双飞对子'
    },
]
export const fushi=[
    {
        ResultID:21941,
        label:1
    },
    {
        ResultID:21942,
        label:2
    },
    {
        ResultID: 21858,
        label: 3
    },
    {
        ResultID: 21859,
        label: 4
    },
    {
        ResultID: 21860,
        label: 5
    },
    {
        ResultID: 21861,
        label:6
    },
    {
        ResultID: 21862,
        label:7
    },
    {
        ResultID: 21863,
        label:8
    },
    {
        ResultID: 21864,
        label:9
    }
]
export const zuxuan6=[
    {
        ResultID:21940,
        label:2
    },
    {
        ResultID: 21886,
        label:3
    },
    {
        ResultID: 3706,
        label:4
    },
    {
        ResultID: 3707,
        label: 5
    },
    {
        ResultID: 3708,
        label: 6
    },
    {
        ResultID: 3709,
        label: 7
    },
    {
        ResultID: 3710,
        label: 8
    },
    {
        ResultID: 21856,
        label: 9
    },
    {
        ResultID: 21857,
        label: 10
    },
    {
        ResultID: 21873,
        label: '一拖二'
    },
    {
        ResultID: 21874,
        label: '一拖三'
    },
    {
        ResultID: 21875,
        label: '一拖四'
    },
    {
        ResultID: 21876,
        label: '一拖五'
    },
    {
        ResultID: 21877,
        label: '一拖六'
    },
    {
        ResultID: 21878,
        label: '一拖七'
    },
    {
        ResultID: 21879,
        label: '一拖八'
    },
    {
        ResultID: 21880,
        label: '一拖九'
    }
]
export const zuxuan3=[
    {
        ResultID: 21883,
        label:'双飞'
    },
    {
        ResultID: 21853,
        label: 2
    },
    {
        ResultID: 3700,
        label: 5
    },
    {
        ResultID: 3701,
        label: 6
    },
    {
        ResultID: 3702,
        label:7
    },
    {
        ResultID: 3703,
        label: 8
    },
    {
        ResultID: 3704,
        label: 9
    },
    {
        ResultID: 3705,
        label: 10
    },
  
    {
        ResultID: 21854,
        label: 3
    },
    {
        ResultID: 21855,
        label: 4
    },
    {
        ResultID: 21865,
        label:'一拖二'
    },
    {
        ResultID: 21866,
        label: '一拖三'
    },
    {
        ResultID: 21867,
        label: '一拖四'
    },
    {
        ResultID: 21868,
        label: '一拖五'
    },
    {
        ResultID: 21869,
        label: '一拖六'
    },
    {
        ResultID: 21870,
        label: '一拖七'
    },
    {
        ResultID: 21871,
        label: '一拖八'
    },
    {
        ResultID: 21872,
        label: '一拖九'
    }
]
export const yizixian=[
    {
        ResultID: 3316,
        label: 0
    },
    {
        ResultID: 3318,
        label: 2
    },
    {
        ResultID: 3320,
        label: 4
    },
    {
        ResultID: 3322,
        label: 6
    },
    {
        ResultID: 3324,
        label: 8
    },
    {
        ResultID: 3317,
        label: 1
    },
    {
        ResultID: 3319,
        label: 3
    },
    {
        ResultID: 3321,
        label: 5
    },
    {
        ResultID: 3323,
        label: 7
    },
    {
        ResultID: 3325,
        label: 9
    }
]
export const erziding=[
    {
        ResultID: 4035,
        label: "00X"
    },
    {
        ResultID: 4036,
        label: "01X"
    },
    {
        ResultID: 4037,
        label: "02X"
    },
    {
        ResultID: 4038,
        label: "03X"
    },
    {
        ResultID: 4039,
        label: "04X"
    },
    {
        ResultID: 4040,
        label: "05X"
    },
    {
        ResultID: 4041,
        label: "06X"
    },
    {
        ResultID: 4042,
        label: "07X"
    },
    {
        ResultID: 4043,
        label: "08X"
    },
    {
        ResultID: 4044,
        label: "09X"
    },
    {
        ResultID: 4045,
        label: "10X"
    },
    {
        ResultID: 4046,
        label: "11X"
    },
    {
        ResultID: 4047,
        label: "12X"
    },
    {
        ResultID: 4048,
        label: "13X"
    },
    {
        ResultID: 4049,
        label: "14X"
    },
    {
        ResultID: 4050,
        label: "15X"
    },
    {
        ResultID: 4051,
        label: "16X"
    },
    {
        ResultID: 4052,
        label: "17X"
    },
    {
        ResultID: 4053,
        label: "18X"
    },
    {
        ResultID: 4054,
        label: "19X"
    },
    {
        ResultID: 4055,
        label: "20X"
    },
    {
        ResultID: 4056,
        label: "21X"
    },
    {
        ResultID: 4057,
        label: "22X"
    },
    {
        ResultID: 4058,
        label: "23X"
    },
    {
        ResultID: 4059,
        label: "24X"
    },
    {
        ResultID: 4060,
        label: "25X"
    },
    {
        ResultID: 4061,
        label: "26X"
    },
    {
        ResultID: 4062,
        label: "27X"
    },
    {
        ResultID: 4063,
        label: "28X"
    },
    {
        ResultID: 4064,
        label: "29X"
    },
    {
        ResultID: 4065,
        label: "30X"
    },
    {
        ResultID: 4066,
        label: "31X"
    },
    {
        ResultID: 4067,
        label: "32X"
    },
    {
        ResultID: 4068,
        label: "33X"
    },
    {
        ResultID: 4069,
        label: "34X"
    },
    {
        ResultID: 4070,
        label: "35X"
    },
    {
        ResultID: 4071,
        label: "36X"
    },
    {
        ResultID: 4072,
        label: "37X"
    },
    {
        ResultID: 4073,
        label: "38X"
    },
    {
        ResultID: 4074,
        label: "39X"
    },
    {
        ResultID: 4075,
        label: "40X"
    },
    {
        ResultID: 4076,
        label: "41X"
    },
    {
        ResultID: 4077,
        label: "42X"
    },
    {
        ResultID: 4078,
        label: "43X"
    },
    {
        ResultID: 4079,
        label: "44X"
    },
    {
        ResultID: 4080,
        label: "45X"
    },
    {
        ResultID: 4081,
        label: "46X"
    },
    {
        ResultID: 4082,
        label: "47X"
    },
    {
        ResultID: 4083,
        label: "48X"
    },
    {
        ResultID: 4084,
        label: "49X"
    },
    {
        ResultID: 4085,
        label: "50X"
    },
    {
        ResultID: 4086,
        label: "51X"
    },
    {
        ResultID: 4087,
        label: "52X"
    },
    {
        ResultID: 4088,
        label: "53X"
    },
    {
        ResultID: 4089,
        label: "54X"
    },
    {
        ResultID: 4090,
        label: "55X"
    },
    {
        ResultID: 4091,
        label: "56X"
    },
    {
        ResultID: 4092,
        label: "57X"
    },
    {
        ResultID: 4093,
        label: "58X"
    },
    {
        ResultID: 4094,
        label: "59X"
    },
    {
        ResultID: 4095,
        label: "60X"
    },
    {
        ResultID: 4096,
        label: "61X"
    },
    {
        ResultID: 4097,
        label: "62X"
    },
    {
        ResultID: 4098,
        label: "63X"
    },
    {
        ResultID: 4099,
        label: "64X"
    },
    {
        ResultID: 4100,
        label: "65X"
    },
    {
        ResultID: 4101,
        label: "66X"
    },
    {
        ResultID: 4102,
        label: "67X"
    },
    {
        ResultID: 4103,
        label: "68X"
    },
    {
        ResultID: 4104,
        label: "69X"
    },
    {
        ResultID: 4105,
        label: "70X"
    },
    {
        ResultID: 4106,
        label: "71X"
    },
    {
        ResultID: 4107,
        label: "72X"
    },
    {
        ResultID: 4108,
        label: "73X"
    },
    {
        ResultID: 4109,
        label: "74X"
    },
    {
        ResultID: 4110,
        label: "75X"
    },
    {
        ResultID: 4111,
        label: "76X"
    },
    {
        ResultID: 4112,
        label: "77X"
    },
    {
        ResultID: 4113,
        label: "78X"
    },
    {
        ResultID: 4114,
        label: "79X"
    },
    {
        ResultID: 4115,
        label: "80X"
    },
    {
        ResultID: 4116,
        label: "81X"
    },
    {
        ResultID: 4117,
        label: "82X"
    },
    {
        ResultID: 4118,
        label: "83X"
    },
    {
        ResultID: 4119,
        label: "84X"
    },
    {
        ResultID: 4120,
        label: "85X"
    },
    {
        ResultID: 4121,
        label: "86X"
    },
    {
        ResultID: 4122,
        label: "87X"
    },
    {
        ResultID: 4123,
        label: "88X"
    },
    {
        ResultID: 4124,
        label: "89X"
    },
    {
        ResultID: 4125,
        label: "90X"
    },
    {
        ResultID: 4126,
        label: "91X"
    },
    {
        ResultID: 4127,
        label: "92X"
    },
    {
        ResultID: 4128,
        label: "93X"
    },
    {
        ResultID: 4129,
        label: "94X"
    },
    {
        ResultID: 4130,
        label: "95X"
    },
    {
        ResultID: 4131,
        label: "96X"
    },
    {
        ResultID: 4132,
        label: "97X"
    },
    {
        ResultID: 4133,
        label: "98X"
    },
    {
        ResultID: 4134,
        label: "99X"
    },
    {
        ResultID: 4135,
        label: "0X0"
    },
    {
        ResultID: 4136,
        label: "0X1"
    },
    {
        ResultID: 4137,
        label: "0X2"
    },
    {
        ResultID: 4138,
        label: "0X3"
    },
    {
        ResultID: 4139,
        label: "0X4"
    },
    {
        ResultID: 4140,
        label: "0X5"
    },
    {
        ResultID: 4141,
        label: "0X6"
    },
    {
        ResultID: 4142,
        label: "0X7"
    },
    {
        ResultID: 4143,
        label: "0X8"
    },
    {
        ResultID: 4144,
        label: "0X9"
    },
    {
        ResultID: 4145,
        label: "1X0"
    },
    {
        ResultID: 4146,
        label: "1X1"
    },
    {
        ResultID: 4147,
        label: "1X2"
    },
    {
        ResultID: 4148,
        label: "1X3"
    },
    {
        ResultID: 4149,
        label: "1X4"
    },
    {
        ResultID: 4150,
        label: "1X5"
    },
    {
        ResultID: 4151,
        label: "1X6"
    },
    {
        ResultID: 4152,
        label: "1X7"
    },
    {
        ResultID: 4153,
        label: "1X8"
    },
    {
        ResultID: 4154,
        label: "1X9"
    },
    {
        ResultID: 4155,
        label: "2X0"
    },
    {
        ResultID: 4156,
        label: "2X1"
    },
    {
        ResultID: 4157,
        label: "2X2"
    },
    {
        ResultID: 4158,
        label: "2X3"
    },
    {
        ResultID: 4159,
        label: "2X4"
    },
    {
        ResultID: 4160,
        label: "2X5"
    },
    {
        ResultID: 4161,
        label: "2X6"
    },
    {
        ResultID: 4162,
        label: "2X7"
    },
    {
        ResultID: 4163,
        label: "2X8"
    },
    {
        ResultID: 4164,
        label: "2X9"
    },
    {
        ResultID: 4165,
        label: "3X0"
    },
    {
        ResultID: 4166,
        label: "3X1"
    },
    {
        ResultID: 4167,
        label: "3X2"
    },
    {
        ResultID: 4168,
        label: "3X3"
    },
    {
        ResultID: 4169,
        label: "3X4"
    },
    {
        ResultID: 4170,
        label: "3X5"
    },
    {
        ResultID: 4171,
        label: "3X6"
    },
    {
        ResultID: 4172,
        label: "3X7"
    },
    {
        ResultID: 4173,
        label: "3X8"
    },
    {
        ResultID: 4174,
        label: "3X9"
    },
    {
        ResultID: 4175,
        label: "4X0"
    },
    {
        ResultID: 4176,
        label: "4X1"
    },
    {
        ResultID: 4177,
        label: "4X2"
    },
    {
        ResultID: 4178,
        label: "4X3"
    },
    {
        ResultID: 4179,
        label: "4X4"
    },
    {
        ResultID: 4180,
        label: "4X5"
    },
    {
        ResultID: 4181,
        label: "4X6"
    },
    {
        ResultID: 4182,
        label: "4X7"
    },
    {
        ResultID: 4183,
        label: "4X8"
    },
    {
        ResultID: 4184,
        label: "4X9"
    },
    {
        ResultID: 4185,
        label: "5X0"
    },
    {
        ResultID: 4186,
        label: "5X1"
    },
    {
        ResultID: 4187,
        label: "5X2"
    },
    {
        ResultID: 4188,
        label: "5X3"
    },
    {
        ResultID: 4189,
        label: "5X4"
    },
    {
        ResultID: 4190,
        label: "5X5"
    },
    {
        ResultID: 4191,
        label: "5X6"
    },
    {
        ResultID: 4192,
        label: "5X7"
    },
    {
        ResultID: 4193,
        label: "5X8"
    },
    {
        ResultID: 4194,
        label: "5X9"
    },
    {
        ResultID: 4195,
        label: "6X0"
    },
    {
        ResultID: 4196,
        label: "6X1"
    },
    {
        ResultID: 4197,
        label: "6X2"
    },
    {
        ResultID: 4198,
        label: "6X3"
    },
    {
        ResultID: 4199,
        label: "6X4"
    },
    {
        ResultID: 4200,
        label: "6X5"
    },
    {
        ResultID: 4201,
        label: "6X6"
    },
    {
        ResultID: 4202,
        label: "6X7"
    },
    {
        ResultID: 4203,
        label: "6X8"
    },
    {
        ResultID: 4204,
        label: "6X9"
    },
    {
        ResultID: 4205,
        label: "7X0"
    },
    {
        ResultID: 4206,
        label: "7X1"
    },
    {
        ResultID: 4207,
        label: "7X2"
    },
    {
        ResultID: 4208,
        label: "7X3"
    },
    {
        ResultID: 4209,
        label: "7X4"
    },
    {
        ResultID: 4210,
        label: "7X5"
    },
    {
        ResultID: 4211,
        label: "7X6"
    },
    {
        ResultID: 4212,
        label: "7X7"
    },
    {
        ResultID: 4213,
        label: "7X8"
    },
    {
        ResultID: 4214,
        label: "7X9"
    },
    {
        ResultID: 4215,
        label: "8X0"
    },
    {
        ResultID: 4216,
        label: "8X1"
    },
    {
        ResultID: 4217,
        label: "8X2"
    },
    {
        ResultID: 4218,
        label: "8X3"
    },
    {
        ResultID: 4219,
        label: "8X4"
    },
    {
        ResultID: 4220,
        label: "8X5"
    },
    {
        ResultID: 4221,
        label: "8X6"
    },
    {
        ResultID: 4222,
        label: "8X7"
    },
    {
        ResultID: 4223,
        label: "8X8"
    },
    {
        ResultID: 4224,
        label: "8X9"
    },
    {
        ResultID: 4225,
        label: "9X0"
    },
    {
        ResultID: 4226,
        label: "9X1"
    },
    {
        ResultID: 4227,
        label: "9X2"
    },
    {
        ResultID: 4228,
        label: "9X3"
    },
    {
        ResultID: 4229,
        label: "9X4"
    },
    {
        ResultID: 4230,
        label: "9X5"
    },
    {
        ResultID: 4231,
        label: "9X6"
    },
    {
        ResultID: 4232,
        label: "9X7"
    },
    {
        ResultID: 4233,
        label: "9X8"
    },
    {
        ResultID: 4234,
        label: "9X9"
    },
    {
        ResultID: 4235,
        label: "X00"
    },
    {
        ResultID: 4236,
        label: "X01"
    },
    {
        ResultID: 4237,
        label: "X02"
    },
    {
        ResultID: 4238,
        label: "X03"
    },
    {
        ResultID: 4239,
        label: "X04"
    },
    {
        ResultID: 4240,
        label: "X05"
    },
    {
        ResultID: 4241,
        label: "X06"
    },
    {
        ResultID: 4242,
        label: "X07"
    },
    {
        ResultID: 4243,
        label: "X08"
    },
    {
        ResultID: 4244,
        label: "X09"
    },
    {
        ResultID: 4245,
        label: "X10"
    },
    {
        ResultID: 4246,
        label: "X11"
    },
    {
        ResultID: 4247,
        label: "X12"
    },
    {
        ResultID: 4248,
        label: "X13"
    },
    {
        ResultID: 4249,
        label: "X14"
    },
    {
        ResultID: 4250,
        label: "X15"
    },
    {
        ResultID: 4251,
        label: "X16"
    },
    {
        ResultID: 4252,
        label: "X17"
    },
    {
        ResultID: 4253,
        label: "X18"
    },
    {
        ResultID: 4254,
        label: "X19"
    },
    {
        ResultID: 4255,
        label: "X20"
    },
    {
        ResultID: 4256,
        label: "X21"
    },
    {
        ResultID: 4257,
        label: "X22"
    },
    {
        ResultID: 4258,
        label: "X23"
    },
    {
        ResultID: 4259,
        label: "X24"
    },
    {
        ResultID: 4260,
        label: "X25"
    },
    {
        ResultID: 4261,
        label: "X26"
    },
    {
        ResultID: 4262,
        label: "X27"
    },
    {
        ResultID: 4263,
        label: "X28"
    },
    {
        ResultID: 4264,
        label: "X29"
    },
    {
        ResultID: 4265,
        label: "X30"
    },
    {
        ResultID: 4266,
        label: "X31"
    },
    {
        ResultID: 4267,
        label: "X32"
    },
    {
        ResultID: 4268,
        label: "X33"
    },
    {
        ResultID: 4269,
        label: "X34"
    },
    {
        ResultID: 4270,
        label: "X35"
    },
    {
        ResultID: 4271,
        label: "X36"
    },
    {
        ResultID: 4272,
        label: "X37"
    },
    {
        ResultID: 4273,
        label: "X38"
    },
    {
        ResultID: 4274,
        label: "X39"
    },
    {
        ResultID: 4275,
        label: "X40"
    },
    {
        ResultID: 4276,
        label: "X41"
    },
    {
        ResultID: 4277,
        label: "X42"
    },
    {
        ResultID: 4278,
        label: "X43"
    },
    {
        ResultID: 4279,
        label: "X44"
    },
    {
        ResultID: 4280,
        label: "X45"
    },
    {
        ResultID: 4281,
        label: "X46"
    },
    {
        ResultID: 4282,
        label: "X47"
    },
    {
        ResultID: 4283,
        label: "X48"
    },
    {
        ResultID: 4284,
        label: "X49"
    },
    {
        ResultID: 4285,
        label: "X50"
    },
    {
        ResultID: 4286,
        label: "X51"
    },
    {
        ResultID: 4287,
        label: "X52"
    },
    {
        ResultID: 4288,
        label: "X53"
    },
    {
        ResultID: 4289,
        label: "X54"
    },
    {
        ResultID: 4290,
        label: "X55"
    },
    {
        ResultID: 4291,
        label: "X56"
    },
    {
        ResultID: 4292,
        label: "X57"
    },
    {
        ResultID: 4293,
        label: "X58"
    },
    {
        ResultID: 4294,
        label: "X59"
    },
    {
        ResultID: 4295,
        label: "X60"
    },
    {
        ResultID: 4296,
        label: "X61"
    },
    {
        ResultID: 4297,
        label: "X62"
    },
    {
        ResultID: 4298,
        label: "X63"
    },
    {
        ResultID: 4299,
        label: "X64"
    },
    {
        ResultID: 4300,
        label: "X65"
    },
    {
        ResultID: 4301,
        label: "X66"
    },
    {
        ResultID: 4302,
        label: "X67"
    },
    {
        ResultID: 4303,
        label: "X68"
    },
    {
        ResultID: 4304,
        label: "X69"
    },
    {
        ResultID: 4305,
        label: "X70"
    },
    {
        ResultID: 4306,
        label: "X71"
    },
    {
        ResultID: 4307,
        label: "X72"
    },
    {
        ResultID: 4308,
        label: "X73"
    },
    {
        ResultID: 4309,
        label: "X74"
    },
    {
        ResultID: 4310,
        label: "X75"
    },
    {
        ResultID: 4311,
        label: "X76"
    },
    {
        ResultID: 4312,
        label: "X77"
    },
    {
        ResultID: 4313,
        label: "X78"
    },
    {
        ResultID: 4314,
        label: "X79"
    },
    {
        ResultID: 4315,
        label: "X80"
    },
    {
        ResultID: 4316,
        label: "X81"
    },
    {
        ResultID: 4317,
        label: "X82"
    },
    {
        ResultID: 4318,
        label: "X83"
    },
    {
        ResultID: 4319,
        label: "X84"
    },
    {
        ResultID: 4320,
        label: "X85"
    },
    {
        ResultID: 4321,
        label: "X86"
    },
    {
        ResultID: 4322,
        label: "X87"
    },
    {
        ResultID: 4323,
        label: "X88"
    },
    {
        ResultID: 4324,
        label: "X89"
    },
    {
        ResultID: 4325,
        label: "X90"
    },
    {
        ResultID: 4326,
        label: "X91"
    },
    {
        ResultID: 4327,
        label: "X92"
    },
    {
        ResultID: 4328,
        label: "X93"
    },
    {
        ResultID: 4329,
        label: "X94"
    },
    {
        ResultID: 4330,
        label: "X95"
    },
    {
        ResultID: 4331,
        label: "X96"
    },
    {
        ResultID: 4332,
        label: "X97"
    },
    {
        ResultID: 4333,
        label: "X98"
    },
    {
        ResultID: 4334,
        label: "X99"
    }
]
export const yiziding=[
    {
        ResultID: 3619,
        label: "0XX"
    },
    {
        ResultID: 3620,
        label: "1XX"
    },
    {
        ResultID: 3621,
        label: "2XX"
    },
    {
        ResultID: 3622,
        label: "3XX"
    },
    {
        ResultID: 3623,
        label: "4XX"
    },
    {
        ResultID: 3624,
        label: "5XX"
    },
    {
        ResultID: 3625,
        label: "6XX"
    },
    {
        ResultID: 3626,
        label: "7XX"
    },
    {
        ResultID: 3627,
        label: "8XX"
    },
    {
        ResultID: 3628,
        label: "9XX"
    },
    {
        ResultID: 3629,
        label: "X0X"
    },
    {
        ResultID: 3630,
        label: "X1X"
    },
    {
        ResultID: 3631,
        label: "X2X"
    },
    {
        ResultID: 3632,
        label: "X3X"
    },
    {
        ResultID: 3633,
        label: "X4X"
    },
    {
        ResultID: 3634,
        label: "X5X"
    },
    {
        ResultID: 3635,
        label: "X6X"
    },
    {
        ResultID: 3636,
        label: "X7X"
    },
    {
        ResultID: 3637,
        label: "X8X"
    },
    {
        ResultID: 3638,
        label: "X9X"
    },
    {
        ResultID: 3639,
        label: "XX0"
    },
    {
        ResultID: 3640,
        label: "XX1"
    },
    {
        ResultID: 3641,
        label: "XX2"
    },
    {
        ResultID: 3642,
        label: "XX3"
    },
    {
        ResultID: 3643,
        label: "XX4"
    },
    {
        ResultID: 3644,
        label: "XX5"
    },
    {
        ResultID: 3645,
        label: "XX6"
    },
    {
        ResultID: 3646,
        label: "XX7"
    },
    {
        ResultID: 3647,
        label: "XX8"
    },
    {
        ResultID: 3648,
        label: "XX9"
    }
]
export const hezhi=[
    {
        ResultID: 3674,
        label: "和值0"
    },
    {
        ResultID: 5344,
        label: "和值1"
    },
    {
        ResultID: 5345,
        label: "和值2"
    },
    {
        ResultID: 5346,
        label: "和值3"
    },
    {
        ResultID: 5347,
        label: "和值4"
    },
    {
        ResultID: 5348,
        label: "和值5"
    },
    {
        ResultID: 5349,
        label: "和值6"
    },
    {
        ResultID: 3675,
        label: "和值7"
    },
    {
        ResultID: 3676,
        label: "和值8"
    },
    {
        ResultID: 3677,
        label: "和值9"
    },
    {
        ResultID: 3678,
        label: "和值10"
    },
    {
        ResultID: 3679,
        label: "和值11"
    },
    {
        ResultID: 3680,
        label: "和值12"
    },
    {
        ResultID: 3681,
        label: "和值13"
    },
    {
        ResultID: 3682,
        label: "和值14"
    },
    {
        ResultID: 3683,
        label: "和值15"
    },
    {
        ResultID: 3684,
        label: "和值16"
    },
    {
        ResultID: 3685,
        label: "和值17"
    },
    {
        ResultID: 3686,
        label: "和值18"
    },
    {
        ResultID: 3687,
        label: "和值19"
    },
    {
        ResultID: 3688,
        label: "和值20"
    },
    {
        ResultID: 3689,
        label: "和值21"
    },
    {
        ResultID: 5350,
        label: "和值22"
    },
    {
        ResultID: 5351,
        label: "和值23"
    },
    {
        ResultID: 5352,
        label: "和值24"
    },
    {
        ResultID: 5353,
        label: "和值25"
    },
    {
        ResultID: 5354,
        label: "和值26"
    },
    {
        ResultID: 5355,
        label: "和值27"
    }
]
export const kua=[
    {
        ResultID: 3711,
        label: "跨0"
    },
    {
        ResultID: 3712,
        label: "跨1"
    },
    {
        ResultID: 3713,
        label: "跨2"
    },
    {
        ResultID: 3714,
        label: "跨3"
    },
    {
        ResultID: 3715,
        label: "跨4"
    },
    {
        ResultID: 3716,
        label: "跨5"
    },
    {
        ResultID: 3717,
        label: "跨6"
    },
    {
        ResultID: 3718,
        label: "跨7"
    },
    {
        ResultID: 3719,
        label: "跨8"
    },
    {
        ResultID: 3720,
        label: "跨9"
    }
]
export const zhixuan = [
    {
        ResultID: 4344,
        label: "000"
    },
    {
        ResultID: 4345,
        label: "001"
    },
    {
        ResultID: 4346,
        label: "002"
    },
    {
        ResultID: 4347,
        label: "003"
    },
    {
        ResultID: 4348,
        label: "004"
    },
    {
        ResultID: 4349,
        label: "005"
    },
    {
        ResultID: 4350,
        label: "006"
    },
    {
        ResultID: 4351,
        label: "007"
    },
    {
        ResultID: 4352,
        label: "008"
    },
    {
        ResultID: 4353,
        label: "009"
    },
    {
        ResultID: 4354,
        label: "010"
    },
    {
        ResultID: 4355,
        label: "011"
    },
    {
        ResultID: 4356,
        label: "012"
    },
    {
        ResultID: 4357,
        label: "013"
    },
    {
        ResultID: 4358,
        label: "014"
    },
    {
        ResultID: 4359,
        label: "015"
    },
    {
        ResultID: 4360,
        label: "016"
    },
    {
        ResultID: 4361,
        label: "017"
    },
    {
        ResultID: 4362,
        label: "018"
    },
    {
        ResultID: 4363,
        label: "019"
    },
    {
        ResultID: 4364,
        label: "020"
    },
    {
        ResultID: 4365,
        label: "021"
    },
    {
        ResultID: 4366,
        label: "022"
    },
    {
        ResultID: 4367,
        label: "023"
    },
    {
        ResultID: 4368,
        label: "024"
    },
    {
        ResultID: 4369,
        label: "025"
    },
    {
        ResultID: 4370,
        label: "026"
    },
    {
        ResultID: 4371,
        label: "027"
    },
    {
        ResultID: 4372,
        label: "028"
    },
    {
        ResultID: 4373,
        label: "029"
    },
    {
        ResultID: 4374,
        label: "030"
    },
    {
        ResultID: 4375,
        label: "031"
    },
    {
        ResultID: 4376,
        label: "032"
    },
    {
        ResultID: 4377,
        label: "033"
    },
    {
        ResultID: 4378,
        label: "034"
    },
    {
        ResultID: 4379,
        label: "035"
    },
    {
        ResultID: 4380,
        label: "036"
    },
    {
        ResultID: 4381,
        label: "037"
    },
    {
        ResultID: 4382,
        label: "038"
    },
    {
        ResultID: 4383,
        label: "039"
    },
    {
        ResultID: 4384,
        label: "040"
    },
    {
        ResultID: 4385,
        label: "041"
    },
    {
        ResultID: 4386,
        label: "042"
    },
    {
        ResultID: 4387,
        label: "043"
    },
    {
        ResultID: 4388,
        label: "044"
    },
    {
        ResultID: 4389,
        label: "045"
    },
    {
        ResultID: 4390,
        label: "046"
    },
    {
        ResultID: 4391,
        label: "047"
    },
    {
        ResultID: 4392,
        label: "048"
    },
    {
        ResultID: 4393,
        label: "049"
    },
    {
        ResultID: 4394,
        label: "050"
    },
    {
        ResultID: 4395,
        label: "051"
    },
    {
        ResultID: 4396,
        label: "052"
    },
    {
        ResultID: 4397,
        label: "053"
    },
    {
        ResultID: 4398,
        label: "054"
    },
    {
        ResultID: 4399,
        label: "055"
    },
    {
        ResultID: 4400,
        label: "056"
    },
    {
        ResultID: 4401,
        label: "057"
    },
    {
        ResultID: 4402,
        label: "058"
    },
    {
        ResultID: 4403,
        label: "059"
    },
    {
        ResultID: 4404,
        label: "060"
    },
    {
        ResultID: 4405,
        label: "061"
    },
    {
        ResultID: 4406,
        label: "062"
    },
    {
        ResultID: 4407,
        label: "063"
    },
    {
        ResultID: 4408,
        label: "064"
    },
    {
        ResultID: 4409,
        label: "065"
    },
    {
        ResultID: 4410,
        label: "066"
    },
    {
        ResultID: 4411,
        label: "067"
    },
    {
        ResultID: 4412,
        label: "068"
    },
    {
        ResultID: 4413,
        label: "069"
    },
    {
        ResultID: 4414,
        label: "070"
    },
    {
        ResultID: 4415,
        label: "071"
    },
    {
        ResultID: 4416,
        label: "072"
    },
    {
        ResultID: 4417,
        label: "073"
    },
    {
        ResultID: 4418,
        label: "074"
    },
    {
        ResultID: 4419,
        label: "075"
    },
    {
        ResultID: 4420,
        label: "076"
    },
    {
        ResultID: 4421,
        label: "077"
    },
    {
        ResultID: 4422,
        label: "078"
    },
    {
        ResultID: 4423,
        label: "079"
    },
    {
        ResultID: 4424,
        label: "080"
    },
    {
        ResultID: 4425,
        label: "081"
    },
    {
        ResultID: 4426,
        label: "082"
    },
    {
        ResultID: 4427,
        label: "083"
    },
    {
        ResultID: 4428,
        label: "084"
    },
    {
        ResultID: 4429,
        label: "085"
    },
    {
        ResultID: 4430,
        label: "086"
    },
    {
        ResultID: 4431,
        label: "087"
    },
    {
        ResultID: 4432,
        label: "088"
    },
    {
        ResultID: 4433,
        label: "089"
    },
    {
        ResultID: 4434,
        label: "090"
    },
    {
        ResultID: 4435,
        label: "091"
    },
    {
        ResultID: 4436,
        label: "092"
    },
    {
        ResultID: 4437,
        label: "093"
    },
    {
        ResultID: 4438,
        label: "094"
    },
    {
        ResultID: 4439,
        label: "095"
    },
    {
        ResultID: 4440,
        label: "096"
    },
    {
        ResultID: 4441,
        label: "097"
    },
    {
        ResultID: 4442,
        label: "098"
    },
    {
        ResultID: 4443,
        label: "099"
    },
    {
        ResultID: 4444,
        label: "100"
    },
    {
        ResultID: 4445,
        label: "101"
    },
    {
        ResultID: 4446,
        label: "102"
    },
    {
        ResultID: 4447,
        label: "103"
    },
    {
        ResultID: 4448,
        label: "104"
    },
    {
        ResultID: 4449,
        label: "105"
    },
    {
        ResultID: 4450,
        label: "106"
    },
    {
        ResultID: 4451,
        label: "107"
    },
    {
        ResultID: 4452,
        label: "108"
    },
    {
        ResultID: 4453,
        label: "109"
    },
    {
        ResultID: 4454,
        label: "110"
    },
    {
        ResultID: 4455,
        label: "111"
    },
    {
        ResultID: 4456,
        label: "112"
    },
    {
        ResultID: 4457,
        label: "113"
    },
    {
        ResultID: 4458,
        label: "114"
    },
    {
        ResultID: 4459,
        label: "115"
    },
    {
        ResultID: 4460,
        label: "116"
    },
    {
        ResultID: 4461,
        label: "117"
    },
    {
        ResultID: 4462,
        label: "118"
    },
    {
        ResultID: 4463,
        label: "119"
    },
    {
        ResultID: 4464,
        label: "120"
    },
    {
        ResultID: 4465,
        label: "121"
    },
    {
        ResultID: 4466,
        label: "122"
    },
    {
        ResultID: 4467,
        label: "123"
    },
    {
        ResultID: 4468,
        label: "124"
    },
    {
        ResultID: 4469,
        label: "125"
    },
    {
        ResultID: 4470,
        label: "126"
    },
    {
        ResultID: 4471,
        label: "127"
    },
    {
        ResultID: 4472,
        label: "128"
    },
    {
        ResultID: 4473,
        label: "129"
    },
    {
        ResultID: 4474,
        label: "130"
    },
    {
        ResultID: 4475,
        label: "131"
    },
    {
        ResultID: 4476,
        label: "132"
    },
    {
        ResultID: 4477,
        label: "133"
    },
    {
        ResultID: 4478,
        label: "134"
    },
    {
        ResultID: 4479,
        label: "135"
    },
    {
        ResultID: 4480,
        label: "136"
    },
    {
        ResultID: 4481,
        label: "137"
    },
    {
        ResultID: 4482,
        label: "138"
    },
    {
        ResultID: 4483,
        label: "139"
    },
    {
        ResultID: 4484,
        label: "140"
    },
    {
        ResultID: 4485,
        label: "141"
    },
    {
        ResultID: 4486,
        label: "142"
    },
    {
        ResultID: 4487,
        label: "143"
    },
    {
        ResultID: 4488,
        label: "144"
    },
    {
        ResultID: 4489,
        label: "145"
    },
    {
        ResultID: 4490,
        label: "146"
    },
    {
        ResultID: 4491,
        label: "147"
    },
    {
        ResultID: 4492,
        label: "148"
    },
    {
        ResultID: 4493,
        label: "149"
    },
    {
        ResultID: 4494,
        label: "150"
    },
    {
        ResultID: 4495,
        label: "151"
    },
    {
        ResultID: 4496,
        label: "152"
    },
    {
        ResultID: 4497,
        label: "153"
    },
    {
        ResultID: 4498,
        label: "154"
    },
    {
        ResultID: 4499,
        label: "155"
    },
    {
        ResultID: 4500,
        label: "156"
    },
    {
        ResultID: 4501,
        label: "157"
    },
    {
        ResultID: 4502,
        label: "158"
    },
    {
        ResultID: 4503,
        label: "159"
    },
    {
        ResultID: 4504,
        label: "160"
    },
    {
        ResultID: 4505,
        label: "161"
    },
    {
        ResultID: 4506,
        label: "162"
    },
    {
        ResultID: 4507,
        label: "163"
    },
    {
        ResultID: 4508,
        label: "164"
    },
    {
        ResultID: 4509,
        label: "165"
    },
    {
        ResultID: 4510,
        label: "166"
    },
    {
        ResultID: 4511,
        label: "167"
    },
    {
        ResultID: 4512,
        label: "168"
    },
    {
        ResultID: 4513,
        label: "169"
    },
    {
        ResultID: 4514,
        label: "170"
    },
    {
        ResultID: 4515,
        label: "171"
    },
    {
        ResultID: 4516,
        label: "172"
    },
    {
        ResultID: 4517,
        label: "173"
    },
    {
        ResultID: 4518,
        label: "174"
    },
    {
        ResultID: 4519,
        label: "175"
    },
    {
        ResultID: 4520,
        label: "176"
    },
    {
        ResultID: 4521,
        label: "177"
    },
    {
        ResultID: 4522,
        label: "178"
    },
    {
        ResultID: 4523,
        label: "179"
    },
    {
        ResultID: 4524,
        label: "180"
    },
    {
        ResultID: 4525,
        label: "181"
    },
    {
        ResultID: 4526,
        label: "182"
    },
    {
        ResultID: 4527,
        label: "183"
    },
    {
        ResultID: 4528,
        label: "184"
    },
    {
        ResultID: 4529,
        label: "185"
    },
    {
        ResultID: 4530,
        label: "186"
    },
    {
        ResultID: 4531,
        label: "187"
    },
    {
        ResultID: 4532,
        label: "188"
    },
    {
        ResultID: 4533,
        label: "189"
    },
    {
        ResultID: 4534,
        label: "190"
    },
    {
        ResultID: 4535,
        label: "191"
    },
    {
        ResultID: 4536,
        label: "192"
    },
    {
        ResultID: 4537,
        label: "193"
    },
    {
        ResultID: 4538,
        label: "194"
    },
    {
        ResultID: 4539,
        label: "195"
    },
    {
        ResultID: 4540,
        label: "196"
    },
    {
        ResultID: 4541,
        label: "197"
    },
    {
        ResultID: 4542,
        label: "198"
    },
    {
        ResultID: 4543,
        label: "199"
    },
    {
        ResultID: 4544,
        label: "200"
    },
    {
        ResultID: 4545,
        label: "201"
    },
    {
        ResultID: 4546,
        label: "202"
    },
    {
        ResultID: 4547,
        label: "203"
    },
    {
        ResultID: 4548,
        label: "204"
    },
    {
        ResultID: 4549,
        label: "205"
    },
    {
        ResultID: 4550,
        label: "206"
    },
    {
        ResultID: 4551,
        label: "207"
    },
    {
        ResultID: 4552,
        label: "208"
    },
    {
        ResultID: 4553,
        label: "209"
    },
    {
        ResultID: 4554,
        label: "210"
    },
    {
        ResultID: 4555,
        label: "211"
    },
    {
        ResultID: 4556,
        label: "212"
    },
    {
        ResultID: 4557,
        label: "213"
    },
    {
        ResultID: 4558,
        label: "214"
    },
    {
        ResultID: 4559,
        label: "215"
    },
    {
        ResultID: 4560,
        label: "216"
    },
    {
        ResultID: 4561,
        label: "217"
    },
    {
        ResultID: 4562,
        label: "218"
    },
    {
        ResultID: 4563,
        label: "219"
    },
    {
        ResultID: 4564,
        label: "220"
    },
    {
        ResultID: 4565,
        label: "221"
    },
    {
        ResultID: 4566,
        label: "222"
    },
    {
        ResultID: 4567,
        label: "223"
    },
    {
        ResultID: 4568,
        label: "224"
    },
    {
        ResultID: 4569,
        label: "225"
    },
    {
        ResultID: 4570,
        label: "226"
    },
    {
        ResultID: 4571,
        label: "227"
    },
    {
        ResultID: 4572,
        label: "228"
    },
    {
        ResultID: 4573,
        label: "229"
    },
    {
        ResultID: 4574,
        label: "230"
    },
    {
        ResultID: 4575,
        label: "231"
    },
    {
        ResultID: 4576,
        label: "232"
    },
    {
        ResultID: 4577,
        label: "233"
    },
    {
        ResultID: 4578,
        label: "234"
    },
    {
        ResultID: 4579,
        label: "235"
    },
    {
        ResultID: 4580,
        label: "236"
    },
    {
        ResultID: 4581,
        label: "237"
    },
    {
        ResultID: 4582,
        label: "238"
    },
    {
        ResultID: 4583,
        label: "239"
    },
    {
        ResultID: 4584,
        label: "240"
    },
    {
        ResultID: 4585,
        label: "241"
    },
    {
        ResultID: 4586,
        label: "242"
    },
    {
        ResultID: 4587,
        label: "243"
    },
    {
        ResultID: 4588,
        label: "244"
    },
    {
        ResultID: 4589,
        label: "245"
    },
    {
        ResultID: 4590,
        label: "246"
    },
    {
        ResultID: 4591,
        label: "247"
    },
    {
        ResultID: 4592,
        label: "248"
    },
    {
        ResultID: 4593,
        label: "249"
    },
    {
        ResultID: 4594,
        label: "250"
    },
    {
        ResultID: 4595,
        label: "251"
    },
    {
        ResultID: 4596,
        label: "252"
    },
    {
        ResultID: 4597,
        label: "253"
    },
    {
        ResultID: 4598,
        label: "254"
    },
    {
        ResultID: 4599,
        label: "255"
    },
    {
        ResultID: 4600,
        label: "256"
    },
    {
        ResultID: 4601,
        label: "257"
    },
    {
        ResultID: 4602,
        label: "258"
    },
    {
        ResultID: 4603,
        label: "259"
    },
    {
        ResultID: 4604,
        label: "260"
    },
    {
        ResultID: 4605,
        label: "261"
    },
    {
        ResultID: 4606,
        label: "262"
    },
    {
        ResultID: 4607,
        label: "263"
    },
    {
        ResultID: 4608,
        label: "264"
    },
    {
        ResultID: 4609,
        label: "265"
    },
    {
        ResultID: 4610,
        label: "266"
    },
    {
        ResultID: 4611,
        label: "267"
    },
    {
        ResultID: 4612,
        label: "268"
    },
    {
        ResultID: 4613,
        label: "269"
    },
    {
        ResultID: 4614,
        label: "270"
    },
    {
        ResultID: 4615,
        label: "271"
    },
    {
        ResultID: 4616,
        label: "272"
    },
    {
        ResultID: 4617,
        label: "273"
    },
    {
        ResultID: 4618,
        label: "274"
    },
    {
        ResultID: 4619,
        label: "275"
    },
    {
        ResultID: 4620,
        label: "276"
    },
    {
        ResultID: 4621,
        label: "277"
    },
    {
        ResultID: 4622,
        label: "278"
    },
    {
        ResultID: 4623,
        label: "279"
    },
    {
        ResultID: 4624,
        label: "280"
    },
    {
        ResultID: 4625,
        label: "281"
    },
    {
        ResultID: 4626,
        label: "282"
    },
    {
        ResultID: 4627,
        label: "283"
    },
    {
        ResultID: 4628,
        label: "284"
    },
    {
        ResultID: 4629,
        label: "285"
    },
    {
        ResultID: 4630,
        label: "286"
    },
    {
        ResultID: 4631,
        label: "287"
    },
    {
        ResultID: 4632,
        label: "288"
    },
    {
        ResultID: 4633,
        label: "289"
    },
    {
        ResultID: 4634,
        label: "290"
    },
    {
        ResultID: 4635,
        label: "291"
    },
    {
        ResultID: 4636,
        label: "292"
    },
    {
        ResultID: 4637,
        label: "293"
    },
    {
        ResultID: 4638,
        label: "294"
    },
    {
        ResultID: 4639,
        label: "295"
    },
    {
        ResultID: 4640,
        label: "296"
    },
    {
        ResultID: 4641,
        label: "297"
    },
    {
        ResultID: 4642,
        label: "298"
    },
    {
        ResultID: 4643,
        label: "299"
    },
    {
        ResultID: 4644,
        label: "300"
    },
    {
        ResultID: 4645,
        label: "301"
    },
    {
        ResultID: 4646,
        label: "302"
    },
    {
        ResultID: 4647,
        label: "303"
    },
    {
        ResultID: 4648,
        label: "304"
    },
    {
        ResultID: 4649,
        label: "305"
    },
    {
        ResultID: 4650,
        label: "306"
    },
    {
        ResultID: 4651,
        label: "307"
    },
    {
        ResultID: 4652,
        label: "308"
    },
    {
        ResultID: 4653,
        label: "309"
    },
    {
        ResultID: 4654,
        label: "310"
    },
    {
        ResultID: 4655,
        label: "311"
    },
    {
        ResultID: 4656,
        label: "312"
    },
    {
        ResultID: 4657,
        label: "313"
    },
    {
        ResultID: 4658,
        label: "314"
    },
    {
        ResultID: 4659,
        label: "315"
    },
    {
        ResultID: 4660,
        label: "316"
    },
    {
        ResultID: 4661,
        label: "317"
    },
    {
        ResultID: 4662,
        label: "318"
    },
    {
        ResultID: 4663,
        label: "319"
    },
    {
        ResultID: 4664,
        label: "320"
    },
    {
        ResultID: 4665,
        label: "321"
    },
    {
        ResultID: 4666,
        label: "322"
    },
    {
        ResultID: 4667,
        label: "323"
    },
    {
        ResultID: 4668,
        label: "324"
    },
    {
        ResultID: 4669,
        label: "325"
    },
    {
        ResultID: 4670,
        label: "326"
    },
    {
        ResultID: 4671,
        label: "327"
    },
    {
        ResultID: 4672,
        label: "328"
    },
    {
        ResultID: 4673,
        label: "329"
    },
    {
        ResultID: 4674,
        label: "330"
    },
    {
        ResultID: 4675,
        label: "331"
    },
    {
        ResultID: 4676,
        label: "332"
    },
    {
        ResultID: 4677,
        label: "333"
    },
    {
        ResultID: 4678,
        label: "334"
    },
    {
        ResultID: 4679,
        label: "335"
    },
    {
        ResultID: 4680,
        label: "336"
    },
    {
        ResultID: 4681,
        label: "337"
    },
    {
        ResultID: 4682,
        label: "338"
    },
    {
        ResultID: 4683,
        label: "339"
    },
    {
        ResultID: 4684,
        label: "340"
    },
    {
        ResultID: 4685,
        label: "341"
    },
    {
        ResultID: 4686,
        label: "342"
    },
    {
        ResultID: 4687,
        label: "343"
    },
    {
        ResultID: 4688,
        label: "344"
    },
    {
        ResultID: 4689,
        label: "345"
    },
    {
        ResultID: 4690,
        label: "346"
    },
    {
        ResultID: 4691,
        label: "347"
    },
    {
        ResultID: 4692,
        label: "348"
    },
    {
        ResultID: 4693,
        label: "349"
    },
    {
        ResultID: 4694,
        label: "350"
    },
    {
        ResultID: 4695,
        label: "351"
    },
    {
        ResultID: 4696,
        label: "352"
    },
    {
        ResultID: 4697,
        label: "353"
    },
    {
        ResultID: 4698,
        label: "354"
    },
    {
        ResultID: 4699,
        label: "355"
    },
    {
        ResultID: 4700,
        label: "356"
    },
    {
        ResultID: 4701,
        label: "357"
    },
    {
        ResultID: 4702,
        label: "358"
    },
    {
        ResultID: 4703,
        label: "359"
    },
    {
        ResultID: 4704,
        label: "360"
    },
    {
        ResultID: 4705,
        label: "361"
    },
    {
        ResultID: 4706,
        label: "362"
    },
    {
        ResultID: 4707,
        label: "363"
    },
    {
        ResultID: 4708,
        label: "364"
    },
    {
        ResultID: 4709,
        label: "365"
    },
    {
        ResultID: 4710,
        label: "366"
    },
    {
        ResultID: 4711,
        label: "367"
    },
    {
        ResultID: 4712,
        label: "368"
    },
    {
        ResultID: 4713,
        label: "369"
    },
    {
        ResultID: 4714,
        label: "370"
    },
    {
        ResultID: 4715,
        label: "371"
    },
    {
        ResultID: 4716,
        label: "372"
    },
    {
        ResultID: 4717,
        label: "373"
    },
    {
        ResultID: 4718,
        label: "374"
    },
    {
        ResultID: 4719,
        label: "375"
    },
    {
        ResultID: 4720,
        label: "376"
    },
    {
        ResultID: 4721,
        label: "377"
    },
    {
        ResultID: 4722,
        label: "378"
    },
    {
        ResultID: 4723,
        label: "379"
    },
    {
        ResultID: 4724,
        label: "380"
    },
    {
        ResultID: 4725,
        label: "381"
    },
    {
        ResultID: 4726,
        label: "382"
    },
    {
        ResultID: 4727,
        label: "383"
    },
    {
        ResultID: 4728,
        label: "384"
    },
    {
        ResultID: 4729,
        label: "385"
    },
    {
        ResultID: 4730,
        label: "386"
    },
    {
        ResultID: 4731,
        label: "387"
    },
    {
        ResultID: 4732,
        label: "388"
    },
    {
        ResultID: 4733,
        label: "389"
    },
    {
        ResultID: 4734,
        label: "390"
    },
    {
        ResultID: 4735,
        label: "391"
    },
    {
        ResultID: 4736,
        label: "392"
    },
    {
        ResultID: 4737,
        label: "393"
    },
    {
        ResultID: 4738,
        label: "394"
    },
    {
        ResultID: 4739,
        label: "395"
    },
    {
        ResultID: 4740,
        label: "396"
    },
    {
        ResultID: 4741,
        label: "397"
    },
    {
        ResultID: 4742,
        label: "398"
    },
    {
        ResultID: 4743,
        label: "399"
    },
    {
        ResultID: 4744,
        label: "400"
    },
    {
        ResultID: 4745,
        label: "401"
    },
    {
        ResultID: 4746,
        label: "402"
    },
    {
        ResultID: 4747,
        label: "403"
    },
    {
        ResultID: 4748,
        label: "404"
    },
    {
        ResultID: 4749,
        label: "405"
    },
    {
        ResultID: 4750,
        label: "406"
    },
    {
        ResultID: 4751,
        label: "407"
    },
    {
        ResultID: 4752,
        label: "408"
    },
    {
        ResultID: 4753,
        label: "409"
    },
    {
        ResultID: 4754,
        label: "410"
    },
    {
        ResultID: 4755,
        label: "411"
    },
    {
        ResultID: 4756,
        label: "412"
    },
    {
        ResultID: 4757,
        label: "413"
    },
    {
        ResultID: 4758,
        label: "414"
    },
    {
        ResultID: 4759,
        label: "415"
    },
    {
        ResultID: 4760,
        label: "416"
    },
    {
        ResultID: 4761,
        label: "417"
    },
    {
        ResultID: 4762,
        label: "418"
    },
    {
        ResultID: 4763,
        label: "419"
    },
    {
        ResultID: 4764,
        label: "420"
    },
    {
        ResultID: 4765,
        label: "421"
    },
    {
        ResultID: 4766,
        label: "422"
    },
    {
        ResultID: 4767,
        label: "423"
    },
    {
        ResultID: 4768,
        label: "424"
    },
    {
        ResultID: 4769,
        label: "425"
    },
    {
        ResultID: 4770,
        label: "426"
    },
    {
        ResultID: 4771,
        label: "427"
    },
    {
        ResultID: 4772,
        label: "428"
    },
    {
        ResultID: 4773,
        label: "429"
    },
    {
        ResultID: 4774,
        label: "430"
    },
    {
        ResultID: 4775,
        label: "431"
    },
    {
        ResultID: 4776,
        label: "432"
    },
    {
        ResultID: 4777,
        label: "433"
    },
    {
        ResultID: 4778,
        label: "434"
    },
    {
        ResultID: 4779,
        label: "435"
    },
    {
        ResultID: 4780,
        label: "436"
    },
    {
        ResultID: 4781,
        label: "437"
    },
    {
        ResultID: 4782,
        label: "438"
    },
    {
        ResultID: 4783,
        label: "439"
    },
    {
        ResultID: 4784,
        label: "440"
    },
    {
        ResultID: 4785,
        label: "441"
    },
    {
        ResultID: 4786,
        label: "442"
    },
    {
        ResultID: 4787,
        label: "443"
    },
    {
        ResultID: 4788,
        label: "444"
    },
    {
        ResultID: 4789,
        label: "445"
    },
    {
        ResultID: 4790,
        label: "446"
    },
    {
        ResultID: 4791,
        label: "447"
    },
    {
        ResultID: 4792,
        label: "448"
    },
    {
        ResultID: 4793,
        label: "449"
    },
    {
        ResultID: 4794,
        label: "450"
    },
    {
        ResultID: 4795,
        label: "451"
    },
    {
        ResultID: 4796,
        label: "452"
    },
    {
        ResultID: 4797,
        label: "453"
    },
    {
        ResultID: 4798,
        label: "454"
    },
    {
        ResultID: 4799,
        label: "455"
    },
    {
        ResultID: 4800,
        label: "456"
    },
    {
        ResultID: 4801,
        label: "457"
    },
    {
        ResultID: 4802,
        label: "458"
    },
    {
        ResultID: 4803,
        label: "459"
    },
    {
        ResultID: 4804,
        label: "460"
    },
    {
        ResultID: 4805,
        label: "461"
    },
    {
        ResultID: 4806,
        label: "462"
    },
    {
        ResultID: 4807,
        label: "463"
    },
    {
        ResultID: 4808,
        label: "464"
    },
    {
        ResultID: 4809,
        label: "465"
    },
    {
        ResultID: 4810,
        label: "466"
    },
    {
        ResultID: 4811,
        label: "467"
    },
    {
        ResultID: 4812,
        label: "468"
    },
    {
        ResultID: 4813,
        label: "469"
    },
    {
        ResultID: 4814,
        label: "470"
    },
    {
        ResultID: 4815,
        label: "471"
    },
    {
        ResultID: 4816,
        label: "472"
    },
    {
        ResultID: 4817,
        label: "473"
    },
    {
        ResultID: 4818,
        label: "474"
    },
    {
        ResultID: 4819,
        label: "475"
    },
    {
        ResultID: 4820,
        label: "476"
    },
    {
        ResultID: 4821,
        label: "477"
    },
    {
        ResultID: 4822,
        label: "478"
    },
    {
        ResultID: 4823,
        label: "479"
    },
    {
        ResultID: 4824,
        label: "480"
    },
    {
        ResultID: 4825,
        label: "481"
    },
    {
        ResultID: 4826,
        label: "482"
    },
    {
        ResultID: 4827,
        label: "483"
    },
    {
        ResultID: 4828,
        label: "484"
    },
    {
        ResultID: 4829,
        label: "485"
    },
    {
        ResultID: 4830,
        label: "486"
    },
    {
        ResultID: 4831,
        label: "487"
    },
    {
        ResultID: 4832,
        label: "488"
    },
    {
        ResultID: 4833,
        label: "489"
    },
    {
        ResultID: 4834,
        label: "490"
    },
    {
        ResultID: 4835,
        label: "491"
    },
    {
        ResultID: 4836,
        label: "492"
    },
    {
        ResultID: 4837,
        label: "493"
    },
    {
        ResultID: 4838,
        label: "494"
    },
    {
        ResultID: 4839,
        label: "495"
    },
    {
        ResultID: 4840,
        label: "496"
    },
    {
        ResultID: 4841,
        label: "497"
    },
    {
        ResultID: 4842,
        label: "498"
    },
    {
        ResultID: 4843,
        label: "499"
    },
    {
        ResultID: 4844,
        label: "500"
    },
    {
        ResultID: 4845,
        label: "501"
    },
    {
        ResultID: 4846,
        label: "502"
    },
    {
        ResultID: 4847,
        label: "503"
    },
    {
        ResultID: 4848,
        label: "504"
    },
    {
        ResultID: 4849,
        label: "505"
    },
    {
        ResultID: 4850,
        label: "506"
    },
    {
        ResultID: 4851,
        label: "507"
    },
    {
        ResultID: 4852,
        label: "508"
    },
    {
        ResultID: 4853,
        label: "509"
    },
    {
        ResultID: 4854,
        label: "510"
    },
    {
        ResultID: 4855,
        label: "511"
    },
    {
        ResultID: 4856,
        label: "512"
    },
    {
        ResultID: 4857,
        label: "513"
    },
    {
        ResultID: 4858,
        label: "514"
    },
    {
        ResultID: 4859,
        label: "515"
    },
    {
        ResultID: 4860,
        label: "516"
    },
    {
        ResultID: 4861,
        label: "517"
    },
    {
        ResultID: 4862,
        label: "518"
    },
    {
        ResultID: 4863,
        label: "519"
    },
    {
        ResultID: 4864,
        label: "520"
    },
    {
        ResultID: 4865,
        label: "521"
    },
    {
        ResultID: 4866,
        label: "522"
    },
    {
        ResultID: 4867,
        label: "523"
    },
    {
        ResultID: 4868,
        label: "524"
    },
    {
        ResultID: 4869,
        label: "525"
    },
    {
        ResultID: 4870,
        label: "526"
    },
    {
        ResultID: 4871,
        label: "527"
    },
    {
        ResultID: 4872,
        label: "528"
    },
    {
        ResultID: 4873,
        label: "529"
    },
    {
        ResultID: 4874,
        label: "530"
    },
    {
        ResultID: 4875,
        label: "531"
    },
    {
        ResultID: 4876,
        label: "532"
    },
    {
        ResultID: 4877,
        label: "533"
    },
    {
        ResultID: 4878,
        label: "534"
    },
    {
        ResultID: 4879,
        label: "535"
    },
    {
        ResultID: 4880,
        label: "536"
    },
    {
        ResultID: 4881,
        label: "537"
    },
    {
        ResultID: 4882,
        label: "538"
    },
    {
        ResultID: 4883,
        label: "539"
    },
    {
        ResultID: 4884,
        label: "540"
    },
    {
        ResultID: 4885,
        label: "541"
    },
    {
        ResultID: 4886,
        label: "542"
    },
    {
        ResultID: 4887,
        label: "543"
    },
    {
        ResultID: 4888,
        label: "544"
    },
    {
        ResultID: 4889,
        label: "545"
    },
    {
        ResultID: 4890,
        label: "546"
    },
    {
        ResultID: 4891,
        label: "547"
    },
    {
        ResultID: 4892,
        label: "548"
    },
    {
        ResultID: 4893,
        label: "549"
    },
    {
        ResultID: 4894,
        label: "550"
    },
    {
        ResultID: 4895,
        label: "551"
    },
    {
        ResultID: 4896,
        label: "552"
    },
    {
        ResultID: 4897,
        label: "553"
    },
    {
        ResultID: 4898,
        label: "554"
    },
    {
        ResultID: 4899,
        label: "555"
    },
    {
        ResultID: 4900,
        label: "556"
    },
    {
        ResultID: 4901,
        label: "557"
    },
    {
        ResultID: 4902,
        label: "558"
    },
    {
        ResultID: 4903,
        label: "559"
    },
    {
        ResultID: 4904,
        label: "560"
    },
    {
        ResultID: 4905,
        label: "561"
    },
    {
        ResultID: 4906,
        label: "562"
    },
    {
        ResultID: 4907,
        label: "563"
    },
    {
        ResultID: 4908,
        label: "564"
    },
    {
        ResultID: 4909,
        label: "565"
    },
    {
        ResultID: 4910,
        label: "566"
    },
    {
        ResultID: 4911,
        label: "567"
    },
    {
        ResultID: 4912,
        label: "568"
    },
    {
        ResultID: 4913,
        label: "569"
    },
    {
        ResultID: 4914,
        label: "570"
    },
    {
        ResultID: 4915,
        label: "571"
    },
    {
        ResultID: 4916,
        label: "572"
    },
    {
        ResultID: 4917,
        label: "573"
    },
    {
        ResultID: 4918,
        label: "574"
    },
    {
        ResultID: 4919,
        label: "575"
    },
    {
        ResultID: 4920,
        label: "576"
    },
    {
        ResultID: 4921,
        label: "577"
    },
    {
        ResultID: 4922,
        label: "578"
    },
    {
        ResultID: 4923,
        label: "579"
    },
    {
        ResultID: 4924,
        label: "580"
    },
    {
        ResultID: 4925,
        label: "581"
    },
    {
        ResultID: 4926,
        label: "582"
    },
    {
        ResultID: 4927,
        label: "583"
    },
    {
        ResultID: 4928,
        label: "584"
    },
    {
        ResultID: 4929,
        label: "585"
    },
    {
        ResultID: 4930,
        label: "586"
    },
    {
        ResultID: 4931,
        label: "587"
    },
    {
        ResultID: 4932,
        label: "588"
    },
    {
        ResultID: 4933,
        label: "589"
    },
    {
        ResultID: 4934,
        label: "590"
    },
    {
        ResultID: 4935,
        label: "591"
    },
    {
        ResultID: 4936,
        label: "592"
    },
    {
        ResultID: 4937,
        label: "593"
    },
    {
        ResultID: 4938,
        label: "594"
    },
    {
        ResultID: 4939,
        label: "595"
    },
    {
        ResultID: 4940,
        label: "596"
    },
    {
        ResultID: 4941,
        label: "597"
    },
    {
        ResultID: 4942,
        label: "598"
    },
    {
        ResultID: 4943,
        label: "599"
    },
    {
        ResultID: 4944,
        label: "600"
    },
    {
        ResultID: 4945,
        label: "601"
    },
    {
        ResultID: 4946,
        label: "602"
    },
    {
        ResultID: 4947,
        label: "603"
    },
    {
        ResultID: 4948,
        label: "604"
    },
    {
        ResultID: 4949,
        label: "605"
    },
    {
        ResultID: 4950,
        label: "606"
    },
    {
        ResultID: 4951,
        label: "607"
    },
    {
        ResultID: 4952,
        label: "608"
    },
    {
        ResultID: 4953,
        label: "609"
    },
    {
        ResultID: 4954,
        label: "610"
    },
    {
        ResultID: 4955,
        label: "611"
    },
    {
        ResultID: 4956,
        label: "612"
    },
    {
        ResultID: 4957,
        label: "613"
    },
    {
        ResultID: 4958,
        label: "614"
    },
    {
        ResultID: 4959,
        label: "615"
    },
    {
        ResultID: 4960,
        label: "616"
    },
    {
        ResultID: 4961,
        label: "617"
    },
    {
        ResultID: 4962,
        label: "618"
    },
    {
        ResultID: 4963,
        label: "619"
    },
    {
        ResultID: 4964,
        label: "620"
    },
    {
        ResultID: 4965,
        label: "621"
    },
    {
        ResultID: 4966,
        label: "622"
    },
    {
        ResultID: 4967,
        label: "623"
    },
    {
        ResultID: 4968,
        label: "624"
    },
    {
        ResultID: 4969,
        label: "625"
    },
    {
        ResultID: 4970,
        label: "626"
    },
    {
        ResultID: 4971,
        label: "627"
    },
    {
        ResultID: 4972,
        label: "628"
    },
    {
        ResultID: 4973,
        label: "629"
    },
    {
        ResultID: 4974,
        label: "630"
    },
    {
        ResultID: 4975,
        label: "631"
    },
    {
        ResultID: 4976,
        label: "632"
    },
    {
        ResultID: 4977,
        label: "633"
    },
    {
        ResultID: 4978,
        label: "634"
    },
    {
        ResultID: 4979,
        label: "635"
    },
    {
        ResultID: 4980,
        label: "636"
    },
    {
        ResultID: 4981,
        label: "637"
    },
    {
        ResultID: 4982,
        label: "638"
    },
    {
        ResultID: 4983,
        label: "639"
    },
    {
        ResultID: 4984,
        label: "640"
    },
    {
        ResultID: 4985,
        label: "641"
    },
    {
        ResultID: 4986,
        label: "642"
    },
    {
        ResultID: 4987,
        label: "643"
    },
    {
        ResultID: 4988,
        label: "644"
    },
    {
        ResultID: 4989,
        label: "645"
    },
    {
        ResultID: 4990,
        label: "646"
    },
    {
        ResultID: 4991,
        label: "647"
    },
    {
        ResultID: 4992,
        label: "648"
    },
    {
        ResultID: 4993,
        label: "649"
    },
    {
        ResultID: 4994,
        label: "650"
    },
    {
        ResultID: 4995,
        label: "651"
    },
    {
        ResultID: 4996,
        label: "652"
    },
    {
        ResultID: 4997,
        label: "653"
    },
    {
        ResultID: 4998,
        label: "654"
    },
    {
        ResultID: 4999,
        label: "655"
    },
    {
        ResultID: 5000,
        label: "656"
    },
    {
        ResultID: 5001,
        label: "657"
    },
    {
        ResultID: 5002,
        label: "658"
    },
    {
        ResultID: 5003,
        label: "659"
    },
    {
        ResultID: 5004,
        label: "660"
    },
    {
        ResultID: 5005,
        label: "661"
    },
    {
        ResultID: 5006,
        label: "662"
    },
    {
        ResultID: 5007,
        label: "663"
    },
    {
        ResultID: 5008,
        label: "664"
    },
    {
        ResultID: 5009,
        label: "665"
    },
    {
        ResultID: 5010,
        label: "666"
    },
    {
        ResultID: 5011,
        label: "667"
    },
    {
        ResultID: 5012,
        label: "668"
    },
    {
        ResultID: 5013,
        label: "669"
    },
    {
        ResultID: 5014,
        label: "670"
    },
    {
        ResultID: 5015,
        label: "671"
    },
    {
        ResultID: 5016,
        label: "672"
    },
    {
        ResultID: 5017,
        label: "673"
    },
    {
        ResultID: 5018,
        label: "674"
    },
    {
        ResultID: 5019,
        label: "675"
    },
    {
        ResultID: 5020,
        label: "676"
    },
    {
        ResultID: 5021,
        label: "677"
    },
    {
        ResultID: 5022,
        label: "678"
    },
    {
        ResultID: 5023,
        label: "679"
    },
    {
        ResultID: 5024,
        label: "680"
    },
    {
        ResultID: 5025,
        label: "681"
    },
    {
        ResultID: 5026,
        label: "682"
    },
    {
        ResultID: 5027,
        label: "683"
    },
    {
        ResultID: 5028,
        label: "684"
    },
    {
        ResultID: 5029,
        label: "685"
    },
    {
        ResultID: 5030,
        label: "686"
    },
    {
        ResultID: 5031,
        label: "687"
    },
    {
        ResultID: 5032,
        label: "688"
    },
    {
        ResultID: 5033,
        label: "689"
    },
    {
        ResultID: 5034,
        label: "690"
    },
    {
        ResultID: 5035,
        label: "691"
    },
    {
        ResultID: 5036,
        label: "692"
    },
    {
        ResultID: 5037,
        label: "693"
    },
    {
        ResultID: 5038,
        label: "694"
    },
    {
        ResultID: 5039,
        label: "695"
    },
    {
        ResultID: 5040,
        label: "696"
    },
    {
        ResultID: 5041,
        label: "697"
    },
    {
        ResultID: 5042,
        label: "698"
    },
    {
        ResultID: 5043,
        label: "699"
    },
    {
        ResultID: 5044,
        label: "700"
    },
    {
        ResultID: 5045,
        label: "701"
    },
    {
        ResultID: 5046,
        label: "702"
    },
    {
        ResultID: 5047,
        label: "703"
    },
    {
        ResultID: 5048,
        label: "704"
    },
    {
        ResultID: 5049,
        label: "705"
    },
    {
        ResultID: 5050,
        label: "706"
    },
    {
        ResultID: 5051,
        label: "707"
    },
    {
        ResultID: 5052,
        label: "708"
    },
    {
        ResultID: 5053,
        label: "709"
    },
    {
        ResultID: 5054,
        label: "710"
    },
    {
        ResultID: 5055,
        label: "711"
    },
    {
        ResultID: 5056,
        label: "712"
    },
    {
        ResultID: 5057,
        label: "713"
    },
    {
        ResultID: 5058,
        label: "714"
    },
    {
        ResultID: 5059,
        label: "715"
    },
    {
        ResultID: 5060,
        label: "716"
    },
    {
        ResultID: 5061,
        label: "717"
    },
    {
        ResultID: 5062,
        label: "718"
    },
    {
        ResultID: 5063,
        label: "719"
    },
    {
        ResultID: 5064,
        label: "720"
    },
    {
        ResultID: 5065,
        label: "721"
    },
    {
        ResultID: 5066,
        label: "722"
    },
    {
        ResultID: 5067,
        label: "723"
    },
    {
        ResultID: 5068,
        label: "724"
    },
    {
        ResultID: 5069,
        label: "725"
    },
    {
        ResultID: 5070,
        label: "726"
    },
    {
        ResultID: 5071,
        label: "727"
    },
    {
        ResultID: 5072,
        label: "728"
    },
    {
        ResultID: 5073,
        label: "729"
    },
    {
        ResultID: 5074,
        label: "730"
    },
    {
        ResultID: 5075,
        label: "731"
    },
    {
        ResultID: 5076,
        label: "732"
    },
    {
        ResultID: 5077,
        label: "733"
    },
    {
        ResultID: 5078,
        label: "734"
    },
    {
        ResultID: 5079,
        label: "735"
    },
    {
        ResultID: 5080,
        label: "736"
    },
    {
        ResultID: 5081,
        label: "737"
    },
    {
        ResultID: 5082,
        label: "738"
    },
    {
        ResultID: 5083,
        label: "739"
    },
    {
        ResultID: 5084,
        label: "740"
    },
    {
        ResultID: 5085,
        label: "741"
    },
    {
        ResultID: 5086,
        label: "742"
    },
    {
        ResultID: 5087,
        label: "743"
    },
    {
        ResultID: 5088,
        label: "744"
    },
    {
        ResultID: 5089,
        label: "745"
    },
    {
        ResultID: 5090,
        label: "746"
    },
    {
        ResultID: 5091,
        label: "747"
    },
    {
        ResultID: 5092,
        label: "748"
    },
    {
        ResultID: 5093,
        label: "749"
    },
    {
        ResultID: 5094,
        label: "750"
    },
    {
        ResultID: 5095,
        label: "751"
    },
    {
        ResultID: 5096,
        label: "752"
    },
    {
        ResultID: 5097,
        label: "753"
    },
    {
        ResultID: 5098,
        label: "754"
    },
    {
        ResultID: 5099,
        label: "755"
    },
    {
        ResultID: 5100,
        label: "756"
    },
    {
        ResultID: 5101,
        label: "757"
    },
    {
        ResultID: 5102,
        label: "758"
    },
    {
        ResultID: 5103,
        label: "759"
    },
    {
        ResultID: 5104,
        label: "760"
    },
    {
        ResultID: 5105,
        label: "761"
    },
    {
        ResultID: 5106,
        label: "762"
    },
    {
        ResultID: 5107,
        label: "763"
    },
    {
        ResultID: 5108,
        label: "764"
    },
    {
        ResultID: 5109,
        label: "765"
    },
    {
        ResultID: 5110,
        label: "766"
    },
    {
        ResultID: 5111,
        label: "767"
    },
    {
        ResultID: 5112,
        label: "768"
    },
    {
        ResultID: 5113,
        label: "769"
    },
    {
        ResultID: 5114,
        label: "770"
    },
    {
        ResultID: 5115,
        label: "771"
    },
    {
        ResultID: 5116,
        label: "772"
    },
    {
        ResultID: 5117,
        label: "773"
    },
    {
        ResultID: 5118,
        label: "774"
    },
    {
        ResultID: 5119,
        label: "775"
    },
    {
        ResultID: 5120,
        label: "776"
    },
    {
        ResultID: 5121,
        label: "777"
    },
    {
        ResultID: 5122,
        label: "778"
    },
    {
        ResultID: 5123,
        label: "779"
    },
    {
        ResultID: 5124,
        label: "780"
    },
    {
        ResultID: 5125,
        label: "781"
    },
    {
        ResultID: 5126,
        label: "782"
    },
    {
        ResultID: 5127,
        label: "783"
    },
    {
        ResultID: 5128,
        label: "784"
    },
    {
        ResultID: 5129,
        label: "785"
    },
    {
        ResultID: 5130,
        label: "786"
    },
    {
        ResultID: 5131,
        label: "787"
    },
    {
        ResultID: 5132,
        label: "788"
    },
    {
        ResultID: 5133,
        label: "789"
    },
    {
        ResultID: 5134,
        label: "790"
    },
    {
        ResultID: 5135,
        label: "791"
    },
    {
        ResultID: 5136,
        label: "792"
    },
    {
        ResultID: 5137,
        label: "793"
    },
    {
        ResultID: 5138,
        label: "794"
    },
    {
        ResultID: 5139,
        label: "795"
    },
    {
        ResultID: 5140,
        label: "796"
    },
    {
        ResultID: 5141,
        label: "797"
    },
    {
        ResultID: 5142,
        label: "798"
    },
    {
        ResultID: 5143,
        label: "799"
    },
    {
        ResultID: 5144,
        label: "800"
    },
    {
        ResultID: 5145,
        label: "801"
    },
    {
        ResultID: 5146,
        label: "802"
    },
    {
        ResultID: 5147,
        label: "803"
    },
    {
        ResultID: 5148,
        label: "804"
    },
    {
        ResultID: 5149,
        label: "805"
    },
    {
        ResultID: 5150,
        label: "806"
    },
    {
        ResultID: 5151,
        label: "807"
    },
    {
        ResultID: 5152,
        label: "808"
    },
    {
        ResultID: 5153,
        label: "809"
    },
    {
        ResultID: 5154,
        label: "810"
    },
    {
        ResultID: 5155,
        label: "811"
    },
    {
        ResultID: 5156,
        label: "812"
    },
    {
        ResultID: 5157,
        label: "813"
    },
    {
        ResultID: 5158,
        label: "814"
    },
    {
        ResultID: 5159,
        label: "815"
    },
    {
        ResultID: 5160,
        label: "816"
    },
    {
        ResultID: 5161,
        label: "817"
    },
    {
        ResultID: 5162,
        label: "818"
    },
    {
        ResultID: 5163,
        label: "819"
    },
    {
        ResultID: 5164,
        label: "820"
    },
    {
        ResultID: 5165,
        label: "821"
    },
    {
        ResultID: 5166,
        label: "822"
    },
    {
        ResultID: 5167,
        label: "823"
    },
    {
        ResultID: 5168,
        label: "824"
    },
    {
        ResultID: 5169,
        label: "825"
    },
    {
        ResultID: 5170,
        label: "826"
    },
    {
        ResultID: 5171,
        label: "827"
    },
    {
        ResultID: 5172,
        label: "828"
    },
    {
        ResultID: 5173,
        label: "829"
    },
    {
        ResultID: 5174,
        label: "830"
    },
    {
        ResultID: 5175,
        label: "831"
    },
    {
        ResultID: 5176,
        label: "832"
    },
    {
        ResultID: 5177,
        label: "833"
    },
    {
        ResultID: 5178,
        label: "834"
    },
    {
        ResultID: 5179,
        label: "835"
    },
    {
        ResultID: 5180,
        label: "836"
    },
    {
        ResultID: 5181,
        label: "837"
    },
    {
        ResultID: 5182,
        label: "838"
    },
    {
        ResultID: 5183,
        label: "839"
    },
    {
        ResultID: 5184,
        label: "840"
    },
    {
        ResultID: 5185,
        label: "841"
    },
    {
        ResultID: 5186,
        label: "842"
    },
    {
        ResultID: 5187,
        label: "843"
    },
    {
        ResultID: 5188,
        label: "844"
    },
    {
        ResultID: 5189,
        label: "845"
    },
    {
        ResultID: 5190,
        label: "846"
    },
    {
        ResultID: 5191,
        label: "847"
    },
    {
        ResultID: 5192,
        label: "848"
    },
    {
        ResultID: 5193,
        label: "849"
    },
    {
        ResultID: 5194,
        label: "850"
    },
    {
        ResultID: 5195,
        label: "851"
    },
    {
        ResultID: 5196,
        label: "852"
    },
    {
        ResultID: 5197,
        label: "853"
    },
    {
        ResultID: 5198,
        label: "854"
    },
    {
        ResultID: 5199,
        label: "855"
    },
    {
        ResultID: 5200,
        label: "856"
    },
    {
        ResultID: 5201,
        label: "857"
    },
    {
        ResultID: 5202,
        label: "858"
    },
    {
        ResultID: 5203,
        label: "859"
    },
    {
        ResultID: 5204,
        label: "860"
    },
    {
        ResultID: 5205,
        label: "861"
    },
    {
        ResultID: 5206,
        label: "862"
    },
    {
        ResultID: 5207,
        label: "863"
    },
    {
        ResultID: 5208,
        label: "864"
    },
    {
        ResultID: 5209,
        label: "865"
    },
    {
        ResultID: 5210,
        label: "866"
    },
    {
        ResultID: 5211,
        label: "867"
    },
    {
        ResultID: 5212,
        label: "868"
    },
    {
        ResultID: 5213,
        label: "869"
    },
    {
        ResultID: 5214,
        label: "870"
    },
    {
        ResultID: 5215,
        label: "871"
    },
    {
        ResultID: 5216,
        label: "872"
    },
    {
        ResultID: 5217,
        label: "873"
    },
    {
        ResultID: 5218,
        label: "874"
    },
    {
        ResultID: 5219,
        label: "875"
    },
    {
        ResultID: 5220,
        label: "876"
    },
    {
        ResultID: 5221,
        label: "877"
    },
    {
        ResultID: 5222,
        label: "878"
    },
    {
        ResultID: 5223,
        label: "879"
    },
    {
        ResultID: 5224,
        label: "880"
    },
    {
        ResultID: 5225,
        label: "881"
    },
    {
        ResultID: 5226,
        label: "882"
    },
    {
        ResultID: 5227,
        label: "883"
    },
    {
        ResultID: 5228,
        label: "884"
    },
    {
        ResultID: 5229,
        label: "885"
    },
    {
        ResultID: 5230,
        label: "886"
    },
    {
        ResultID: 5231,
        label: "887"
    },
    {
        ResultID: 5232,
        label: "888"
    },
    {
        ResultID: 5233,
        label: "889"
    },
    {
        ResultID: 5234,
        label: "890"
    },
    {
        ResultID: 5235,
        label: "891"
    },
    {
        ResultID: 5236,
        label: "892"
    },
    {
        ResultID: 5237,
        label: "893"
    },
    {
        ResultID: 5238,
        label: "894"
    },
    {
        ResultID: 5239,
        label: "895"
    },
    {
        ResultID: 5240,
        label: "896"
    },
    {
        ResultID: 5241,
        label: "897"
    },
    {
        ResultID: 5242,
        label: "898"
    },
    {
        ResultID: 5243,
        label: "899"
    },
    {
        ResultID: 5244,
        label: "900"
    },
    {
        ResultID: 5245,
        label: "901"
    },
    {
        ResultID: 5246,
        label: "902"
    },
    {
        ResultID: 5247,
        label: "903"
    },
    {
        ResultID: 5248,
        label: "904"
    },
    {
        ResultID: 5249,
        label: "905"
    },
    {
        ResultID: 5250,
        label: "906"
    },
    {
        ResultID: 5251,
        label: "907"
    },
    {
        ResultID: 5252,
        label: "908"
    },
    {
        ResultID: 5253,
        label: "909"
    },
    {
        ResultID: 5254,
        label: "910"
    },
    {
        ResultID: 5255,
        label: "911"
    },
    {
        ResultID: 5256,
        label: "912"
    },
    {
        ResultID: 5257,
        label: "913"
    },
    {
        ResultID: 5258,
        label: "914"
    },
    {
        ResultID: 5259,
        label: "915"
    },
    {
        ResultID: 5260,
        label: "916"
    },
    {
        ResultID: 5261,
        label: "917"
    },
    {
        ResultID: 5262,
        label: "918"
    },
    {
        ResultID: 5263,
        label: "919"
    },
    {
        ResultID: 5264,
        label: "920"
    },
    {
        ResultID: 5265,
        label: "921"
    },
    {
        ResultID: 5266,
        label: "922"
    },
    {
        ResultID: 5267,
        label: "923"
    },
    {
        ResultID: 5268,
        label: "924"
    },
    {
        ResultID: 5269,
        label: "925"
    },
    {
        ResultID: 5270,
        label: "926"
    },
    {
        ResultID: 5271,
        label: "927"
    },
    {
        ResultID: 5272,
        label: "928"
    },
    {
        ResultID: 5273,
        label: "929"
    },
    {
        ResultID: 5274,
        label: "930"
    },
    {
        ResultID: 5275,
        label: "931"
    },
    {
        ResultID: 5276,
        label: "932"
    },
    {
        ResultID: 5277,
        label: "933"
    },
    {
        ResultID: 5278,
        label: "934"
    },
    {
        ResultID: 5279,
        label: "935"
    },
    {
        ResultID: 5280,
        label: "936"
    },
    {
        ResultID: 5281,
        label: "937"
    },
    {
        ResultID: 5282,
        label: "938"
    },
    {
        ResultID: 5283,
        label: "939"
    },
    {
        ResultID: 5284,
        label: "940"
    },
    {
        ResultID: 5285,
        label: "941"
    },
    {
        ResultID: 5286,
        label: "942"
    },
    {
        ResultID: 5287,
        label: "943"
    },
    {
        ResultID: 5288,
        label: "944"
    },
    {
        ResultID: 5289,
        label: "945"
    },
    {
        ResultID: 5290,
        label: "946"
    },
    {
        ResultID: 5291,
        label: "947"
    },
    {
        ResultID: 5292,
        label: "948"
    },
    {
        ResultID: 5293,
        label: "949"
    },
    {
        ResultID: 5294,
        label: "950"
    },
    {
        ResultID: 5295,
        label: "951"
    },
    {
        ResultID: 5296,
        label: "952"
    },
    {
        ResultID: 5297,
        label: "953"
    },
    {
        ResultID: 5298,
        label: "954"
    },
    {
        ResultID: 5299,
        label: "955"
    },
    {
        ResultID: 5300,
        label: "956"
    },
    {
        ResultID: 5301,
        label: "957"
    },
    {
        ResultID: 5302,
        label: "958"
    },
    {
        ResultID: 5303,
        label: "959"
    },
    {
        ResultID: 5304,
        label: "960"
    },
    {
        ResultID: 5305,
        label: "961"
    },
    {
        ResultID: 5306,
        label: "962"
    },
    {
        ResultID: 5307,
        label: "963"
    },
    {
        ResultID: 5308,
        label: "964"
    },
    {
        ResultID: 5309,
        label: "965"
    },
    {
        ResultID: 5310,
        label: "966"
    },
    {
        ResultID: 5311,
        label: "967"
    },
    {
        ResultID: 5312,
        label: "968"
    },
    {
        ResultID: 5313,
        label: "969"
    },
    {
        ResultID: 5314,
        label: "970"
    },
    {
        ResultID: 5315,
        label: "971"
    },
    {
        ResultID: 5316,
        label: "972"
    },
    {
        ResultID: 5317,
        label: "973"
    },
    {
        ResultID: 5318,
        label: "974"
    },
    {
        ResultID: 5319,
        label: "975"
    },
    {
        ResultID: 5320,
        label: "976"
    },
    {
        ResultID: 5321,
        label: "977"
    },
    {
        ResultID: 5322,
        label: "978"
    },
    {
        ResultID: 5323,
        label: "979"
    },
    {
        ResultID: 5324,
        label: "980"
    },
    {
        ResultID: 5325,
        label: "981"
    },
    {
        ResultID: 5326,
        label: "982"
    },
    {
        ResultID: 5327,
        label: "983"
    },
    {
        ResultID: 5328,
        label: "984"
    },
    {
        ResultID: 5329,
        label: "985"
    },
    {
        ResultID: 5330,
        label: "986"
    },
    {
        ResultID: 5331,
        label: "987"
    },
    {
        ResultID: 5332,
        label: "988"
    },
    {
        ResultID: 5333,
        label: "989"
    },
    {
        ResultID: 5334,
        label: "990"
    },
    {
        ResultID: 5335,
        label: "991"
    },
    {
        ResultID: 5336,
        label: "992"
    },
    {
        ResultID: 5337,
        label: "993"
    },
    {
        ResultID: 5338,
        label: "994"
    },
    {
        ResultID: 5339,
        label: "995"
    },
    {
        ResultID: 5340,
        label: "996"
    },
    {
        ResultID: 5341,
        label: "997"
    },
    {
        ResultID: 5342,
        label: "998"
    },
    {
        ResultID: 5343,
        label: "999"
    }
]
export const daxiaodanshuang=[
    {ResultID:3692,label:'单'},
    {ResultID:3693,label:'双'},
    {ResultID:3690,label:'大'},
    {ResultID:3691,label:'小'}
]